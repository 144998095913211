import { FC, ButtonHTMLAttributes, MouseEvent, ReactNode } from 'react';

interface TrinusDefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  title?: string;
  width?: string | number;
  height?: string | number;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  Icon?: React.ComponentType<any>;
  iconPosition?: 'before' | 'after';
  disabled?: boolean;
}

const TrinusDefaultButton: FC<TrinusDefaultButtonProps> = ({
  children,
  title,
  width = '100%',
  height = '32px',
  onClick,
  disabled,
  className,
  ...props
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!disabled) {
      onClick(e);
    }
  };

  const baseStyles = `
    flex items-center justify-center rounded-lg p-2 pl-4 pr-3 gap-2
    font-asap font-semibold Content-sm
  `;

  return (
    <button
      className={`${baseStyles} ${className}`}
      style={{ width, height }}
      onClick={handleClick}
      disabled={disabled}
      {...props}>
      {children}
      {title}
    </button>
  );
};

export default TrinusDefaultButton;