import MicrosoftLogin from 'react-microsoft-login';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState, ChangeEvent, useEffect, FormHTMLAttributes } from 'react';

import trinusLogoBlue from '../../assets/images/logo-trinus-blue-100.png';
import trinusLogoHollow from '../../assets/images/logo-trinus-hollow-effect-100.png';

import Auth from '../../services/ms-core-trinus-auth/controllers/auth';
import { AuthToken } from '../../services/ms-core-trinus-auth/models/auth';

import TrinusTextInput from '../../modules/trinus-forms/trinus-text-input';
import TrinusPasswordInput from '../../modules/trinus-forms/trinus-password-input';
import TrinusDefaultButton from '../../modules/trinus-buttons/trinus-default-button';

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  title: string;
}

const FormLogin: FC<FormProps> = ({ title, ...props }) => {
  const [entitySessionData, setEntitySessionData] = useState<AuthToken>();
  const [key, setKey] = useState<string>('');
  const [secret, setSecret] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const [isLoginButtonEnable, setLoginButtonEnable] = useState<boolean>(true);
  const [isLoginTrinusButtonEnable, setLoginTrinusButtonEnable] = useState<boolean>(true);

  const clientId = process.env.MS_CLIENT_ID;
  const tenantUrl = process.env.MS_TENANT_URL;

  const [errors, setErrors] = useState<{ [field: string]: string }>({
    key: '',
    secret: '',
    form: ''
  });

  useEffect(() => {
    const entitySession = new CustomEvent('entitySessionReceived', {
      detail: entitySessionData
    });

    window.dispatchEvent(entitySession);
  }, [entitySessionData]);

  const renderErrorMsg = () => {
    if (!errorMsg) {
      return <></>;
    }

    return <p className="text-sm text-red-600 mt-2">{errorMsg}</p>;
  };

  const loginHandler = async (err: any, data: any) => {
    const eMsg = 'Não foi possível efetuar o login no momento. Tente novamente mais tarde.';

    if (err || !data?.idToken) {
      setErrorMsg(eMsg);
      setLoading(false);
      handleAuthToken(undefined);
      return;
    }

    const result = await Auth.signInMicrosoft(data.idToken);

    if (result?.status !== 201) {
      handleAuthToken(undefined);
      setErrorMsg(result?.response?.data?.message || eMsg);
      setLoading(false);
      return;
    }

    const loginOrigin = 'SSO';
    const accessTime = new Date();
    const userLanguage = navigator?.language || 'pt-BR';
    const lastAccess = `${accessTime.toLocaleDateString(userLanguage)}, ${accessTime.toLocaleTimeString(userLanguage)}`;
    handleAuthToken({...result.data, loginOrigin, lastAccess});
    setLoading(false);
  };

  const handleKeySecretLoginButton = () => {
    if (isLoginTrinusButtonEnable) {
      setLoading(true);
      setLoginButtonEnable(false);
    }
  };

  const handleMSLoginButton = () => {
    setLoginTrinusButtonEnable(false);
  };

  const handleKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKey(e.target.value);
  };

  const handleSecretChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSecret(e.target.value);
  };

  const handleAuthToken = (data: AuthToken) => {
    if (!data) {
      setEntitySessionData(undefined);
      setLoginButtonEnable(true);
      return;
    }

    setEntitySessionData(data);
  };

  const renderLoading = () => {
    if (!loading) {
      return <></>;
    }

    return (
      <div className="w-62 h-8 flex flex-row justify-center items-center rounded-lg p-5">
        <FontAwesomeIcon icon={faSpinner} className="animate-spin"/>
      </div>
    );    
  };

  const validateFields = () => {
    const newErrors = {
      key: '',
      secret: '',
      form: ''
    };

    const eMsg = 'O campo é obrigatório';

    if (!key) {
      newErrors.key = eMsg;
    }
    if (!secret) {
      newErrors.secret = eMsg;
    }
    setErrors(newErrors);

    return key && secret;
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    handleMSLoginButton();

    const result = await Auth.signIn(key, secret);
    if (result.status !== 201 || !result.data) {
      const eMsg = 'Não foi possível efetuar o login no momento. Tente novamente mais tarde.';
      setErrors((oldErrors) => ({
        ...oldErrors,
        form: result?.response?.data?.message || eMsg
      }));

      setLoginButtonEnable(true);
      setLoginTrinusButtonEnable(true);
    }
    else {
      const accessTime = new Date();
      const userLanguage = navigator?.language || 'pt-BR';
      const lastAccess = 
      `${accessTime.toLocaleDateString(userLanguage)}, ${accessTime.toLocaleTimeString(userLanguage)}`;
      handleAuthToken({...result.data, lastAccess});
    }
  };

  const renderFormLabel = () => {
    return (
      <div className="flex flex-row items-center gap-2 w-full h-full pt-8 pb-8">
        <div className="w-full bg-gray-200 h-0.5" />
        <span className="whitespace-nowrap text-sm"> ou </span>
        <div className="w-full bg-gray-200 h-0.5" />
      </div>
    );
  };

  const renderFormErrorMessage = () => {
    if (!errors.form) {
      return <></>;
    }

    return <p className="text-sm text-red-600 mt-2">{errors.form}</p>;
  };

  const renderLoginTrinusButton = () => {
    if (loading) {
      return <></>;
    }

    return (
      <TrinusDefaultButton
        className={'trinus-def-button'}
        onClick={() => handleKeySecretLoginButton()}
      >
        <img src={trinusLogoHollow} width={40} />
        Login corporativo
      </TrinusDefaultButton>
    );
  };

  return (
    <form className="flex flex-col p-5 bg-white rounded shadow-md w-80" {...props}>
      <img src={trinusLogoBlue} />

      <h2 className="mb-5 pt-4 text-center">{title}</h2>

      {renderLoading()}

      <MicrosoftLogin
        debug
        clientId={clientId}
        tenantUrl={tenantUrl}
        authCallback={loginHandler}
      >
        {renderLoginTrinusButton()}
      </MicrosoftLogin>

      {renderErrorMsg()}

      {renderFormLabel()}

      <TrinusTextInput
        id="key-input"
        label="Usuário"
        value={key}
        onChange={handleKeyChange}
        errorMessage={errors.key}
      />

      <TrinusPasswordInput
        id="secret-input"
        label="Senha"
        value={secret}
        onChange={handleSecretChange}
        errorMessage={errors.secret}
      />

      <TrinusDefaultButton
        title="Login"
        className="trinus-def-button"
        onClick={handleSubmit}
        disabled={!isLoginButtonEnable}
      />

      {renderFormErrorMessage()}
    </form>
  );
};

export default FormLogin;