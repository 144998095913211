import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState
} from 'react';

interface TrinusTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;

  label?: string;
  placeholder?: string;
  width?: string | number;
  height?: string | number;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  className?: string;
  errorMessage?: string;
}

const TrinusTextInput: FC<TrinusTextInputProps> = ({
  id,
  label,
  width = '100%',
  height = '100%',
  required,
  onChange,
  placeholder,
  minLength,
  maxLength,
  className,
  errorMessage,
  ...props
}) => {
  const [textValue, setTextValue] = useState('');
  const [isValid, setValid] = useState(true);
  const [displayedErrorMessage, setDisplayedErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      setValid(false);
      setDisplayedErrorMessage(errorMessage);
    }
    else {
      setValid(true);
      setDisplayedErrorMessage('');
    }
  }, [errorMessage]);

  const handleValidation = () => {
    if (required && !textValue) {
      setValid(false);
      setDisplayedErrorMessage('Este campo precisa ser preenchido');
    }
    else if (textValue.trim().length < minLength) {
      setValid(false);
      setDisplayedErrorMessage(
        `O campo requer no mínimo ${minLength} caracteres`
      );
    }
    else {
      setDisplayedErrorMessage('');
      setValid(true);
    }
  };

  const handleOnFocus = () => {
    setDisplayedErrorMessage('');
    setValid(true);
  };

  const handleOnBlur = () => {
    handleValidation();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);
    onChange(e);
  };

  return (
    <div className={'relative flex flex-col ' + className} style={{ width, height }}>
      <input
        type="text"
        id={id}
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        onChange={(e) => handleOnChange(e)}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        {...props}

        className={'trinus-def-form-input peer'}
      />

      <label htmlFor={id} className={'trinus-def-form-label'}>
        {label}
      </label>

      <div className="h-4 mb-2">
        <span
          className="trinus-def-form-validation"
          style={{
            visibility: isValid ? 'hidden' : 'visible',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {displayedErrorMessage}
        </span>
      </div>
    </div>
  );
};

export default TrinusTextInput;