import { navigateToUrl } from 'single-spa';
import './index.css';
import Login from './pages/login';

export default function Root(/*props*/) {
  const sessionData = JSON.parse(
    sessionStorage.getItem('entitySession')
  );

  if (sessionData?.token) {
    navigateToUrl('/app');
    return;
  }
  
  return <Login />;
}