import { AxiosResponse } from 'axios';
import Context from '../../context';
import { AuthToken, AuthErrors } from '../../models/auth';

class Auth {
  /* Sobre esta função */ /**
   * Autenticar e logar usuário Microsoft

   * @param {string} microsoftToken Recebe o token de autenticação da Microsoft para identificar o email
   * do usuário que deseja fazer login
  **/
  public async signInMicrosoft(
    microsoftToken: string
  ): Promise<AxiosResponse<AuthToken> & AuthErrors> {
    const config = {
      headers: {
        'app-origin': process.env.APP_ORIGIN_CORE_TRINUS
      }
    };

    return await Context.post(
      '/auth/microsoft/token',
      { microsoftToken },
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Autenticar e logar usuário com key e secret
   * @param {string} key Recebe a key do usuário que deseja fazer login
   * @param {string} secret Recebe a secret do usuário que deseja fazer login
   **/
  public async signIn(
    key: string,
    secret: string
  ): Promise<AxiosResponse<AuthToken> & AuthErrors> {
    return await Context.post('/auth/token', { key, secret })
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }
}

export default new Auth();