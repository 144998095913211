import FormLogin from '../../components/form-login';

const Login: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <FormLogin title="Entrar com" />
    </div>
  );
};

export default Login;